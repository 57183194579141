import React from "react"
import Form from "../../form"
import { createMarkup } from "../../../../utils/markup-creator"
import BackgroundImage from "../components/background-image"
import { SectionType } from "../../../types"

type SectionProps = {
  section: SectionType
  index: any
  [key: string]: any
}

const FormHero = ({ section, index, ...props }: SectionProps) => {

  return (
    <div className={`relative w-full`}>
      {section.advanced?.backgroundImageLg && !section.advanced?.background2ImageLg && (
        <BackgroundImage
          url={section.advanced?.backgroundImageLg}
          mobileUrl={section.advanced?.backgroundImageSm}
          height={section.advanced?.backgroundHeight}
          backgroundPosition={section.advanced?.backgroundPosition}
          backgroundColor={section.advanced?.backgroundColor}
          backgroundFit={section.advanced?.backgroundFit}
        />
      )}
      <div className="relative md:grid md:grid-cols-2 h-full" style={!section.advanced?.class ? { height: `93vh` } : undefined}>
        <div className={`relative w-full h-full ${section.advanced?.class || ``}`}>
          {section.advanced?.backgroundImageLg && section.advanced?.background2ImageLg && (
            <BackgroundImage
              url={section.advanced?.backgroundImageLg}
              mobileUrl={section.advanced?.backgroundImageSm}
              height={section.advanced?.backgroundHeight}
              backgroundPosition={section.advanced?.backgroundPosition}
              backgroundColor={section.advanced?.backgroundColor}
              backgroundFit={section.advanced?.backgroundFit}
            />
          )}
          <div className="w-9/12 md:w-8/12 lg:w-half-screen mx-auto lg:mx-0 lg:pr-24 xl:pr-28 lg:float-right">{section.blurb && <div dangerouslySetInnerHTML={createMarkup(section.blurb)} />}</div>
          <div className="clear-both"></div>
        </div>

        <div className={`relative w-full ${section.advanced?.class2 || ``}`}>
          {section.advanced?.background2ImageLg && (
            <BackgroundImage
              url={section.advanced?.background2ImageLg}
              mobileUrl={section.advanced?.background2ImageSm}
              height={section.advanced?.background2Height}
              backgroundPosition={section.advanced?.background2Position}
              backgroundColor={section.advanced?.background2Color}
              backgroundFit={section.advanced?.background2Fit}
            />
          )}
          <div className="w-9/12 md:w-8/12 lg:w-half-screen mx-auto lg:mx-0 lg:pl-24 xl:pl-32">
            {section.blurb2 && <div dangerouslySetInnerHTML={createMarkup(section.blurb2)} />}
            <Form form={section.form} />
          </div>
          <div className="clear-both"></div>
        </div>
      </div>
    </div>
  )
}

export default FormHero
